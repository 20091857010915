/*
 * Project: Overlay Nav Plugin
 * Link: https://github.com/jquery-boilerplate/jquery-boilerplate/blob/master/src/jquery.boilerplate.js
 */

// the semi-colon before function invocation is a safety net against concatenated
// scripts and/or other plugins which may not be closed properly.
;(function ($, window, document, undefined) {

    'use strict';

    // undefined is used here as the undefined global variable in ECMAScript 3 is
    // mutable (ie. it can be changed by someone else). undefined isn't really being
    // passed in so we can ensure the value of it is truly undefined. In ES5, undefined
    // can no longer be modified.

    // window and document are passed through as local variables rather than global
    // as this (slightly) quickens the resolution process and can be more efficiently
    // minified (especially when both are regularly referenced in your plugin).

    // Create the defaults once
    var pluginName = 'overlayNav';
    var defaults = {
        navState: 'closed',
        openIconBarColour: '#0599d6'
    };

    // The actual plugin constructor
    function Plugin (element, options) {
        this.element = element;

        // jQuery has an extend method which merges the contents of two or
        // more objects, storing the result in the first object. The first object
        // is generally empty as we don't want to alter the default options for
        // future instances of the plugin
        this.settings = $.extend({}, defaults, options);
        this._defaults = defaults;
        this._name = pluginName;
        this.init();
    }

    // Avoid Plugin.prototype conflicts
    $.extend(Plugin.prototype, {
        init: function() {

            // Initialise the colours
            this.settings.closedIconBarColour = this.rgb2hex( $('.icon-bar').css('backgroundColor') );

            // Initialise event handlers
            this.initEvents();

        },
        initEvents: function() {

            // Used to fix scoping issues
            var self = this;

            $(self.element).on('click', function() {
                self.toggleNav();
            });

            $(window).on('resize', function() {
                if (self.settings.navState === 'open') {
                    self.toggleNav();
                }
            });

        },
        rgb2hex: function(rgb) {

            if (/^#[0-9A-F]{6}$/i.test(rgb)) {
                return rgb;
            }
            rgb = rgb.match(/^rgb\((\d+),\s*(\d+),\s*(\d+)\)$/);
            function hex(x) {
                return ('0' + parseInt(x, 0).toString(16) ).slice(-2);
            }
            return '#' + hex(rgb[1]) + hex(rgb[2]) + hex(rgb[3]);

        },
        toggleNav: function() {

            // Used to fix scoping issues
            var self = this;

            // If nav is closed
            if (self.settings.navState === 'closed') {

                self.settings.navState = 'open';

                $('.navbar-overlay').velocity({
                    opacity: [1, 0],
                    scale: [1, 0.95]
                }, {
                    display: 'table',
                    duration: 300
                });

                $('#navbar-hamburger .first')
                    .velocity({
                        top: '7px'
                    }, {
                        duration: 300
                    })
                    .velocity({
                        rotateZ: '45deg',
                        backgroundColor: self.settings.openIconBarColour
                    }, {
                        duration: 300
                    });


                $('#navbar-hamburger .second')
                    .velocity({
                        opacity: 0
                    }, {
                        duration: 0,
                        delay: 300
                    });


                $('#navbar-hamburger .third')
                    .velocity({
                        top: '7px'
                    }, {
                        duration: 300
                    })
                    .velocity({
                        rotateZ: '-45deg',
                        backgroundColor: self.settings.openIconBarColour
                    }, {
                        duration: 300
                    });


            } else {

                self.settings.navState = 'closed';

                $('.navbar-overlay').velocity('reverse', { display: 'none' });

                $('#navbar-hamburger .first')
                    .velocity({
                        rotateZ: '0deg',
                        backgroundColor: self.settings.closedIconBarColour
                    }, {
                        duration: 300
                    })
                    .velocity({
                        top: '0px'
                    }, {
                        duration: 300
                    });

                $('#navbar-hamburger .second')
                    .velocity({
                        opacity: 1
                    }, {
                        duration: 0,
                        delay: 300
                    });

                $('#navbar-hamburger .third')
                    .velocity({
                        rotateZ: '0deg',
                        backgroundColor: self.settings.closedIconBarColour
                    }, {
                        duration: 300
                    })
                    .velocity({
                        top: '14px'
                    }, {
                        duration: 300
                    });

                setTimeout(function(){

                    // Clear all of the inline styles
                    // once animation is complete
                    $('.navbar-overlay').removeAttr('style');
                    $('.icon-bar').removeAttr('style');

                }, 600);

            }

            return false;
        }
    });

    // A really lightweight plugin wrapper around the constructor,
    // preventing against multiple instantiations
    $.fn[ pluginName ] = function(options) {
        return this.each(function() {
            if (!$.data(this, 'plugin_' + pluginName)) {
                $.data(this, 'plugin_' +
                    pluginName, new Plugin(this, options));
            }
        });
    };

})(jQuery, window, document);