/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 *
 * Google CDN, Latest jQuery
 * To use the default WordPress version of jQuery, go to lib/config.php and
 * remove or comment out: add_theme_support('jquery-cdn');
 * ======================================================================== */

(function($) {

    // Use this variable to set up the common and page specific functions. If you
    // rename this variable, you will also need to rename the namespace below.
    var MBO = {
        // All pages
        'common': {
            init: function() {

                // Lazy sizes
                // https://github.com/aFarkas/lazysizes
                // ==========================================

                window.lazySizesConfig = window.lazySizesConfig || {};
                lazySizesConfig.loadMode = 1;


                // Fire up the overlay nav
                $('#navbar-hamburger').overlayNav();

                // Focus on the input on modal show
                $('#search-modal').on('shown.bs.modal', function() {
                    $('.search-form-control').focus();
                });

                // Show hide the category filters
                $( '.filter-text' ).on( 'click', function() {
                    $('.dropdown').toggleClass('open');
                });

                // Display not featured clients
                $( '.client-toggle .btn' ).on( 'click', function() {
                    $('.clients-not-featured').slideToggle();
                });

            }
        },

        // Home page
        'home': {
            init: function() {

                $('.flexslider').flexslider({
                    pauseOnHover   : false,
                    animation      : 'fade',
                    animationLoop  : true,
                    slideshow      : true,
                    slideshowSpeed : 4000,
                    animationSpeed : 1200,
                    controlNav     : false,
                    directionNav   : true
                });


                // Navbar colour on scroll
                // ==========================================

                $(window).scroll(function() {

                    var scrollTop = $(window).scrollTop();

                    if (scrollTop >= 10) {
                        $('body').addClass('has-scrolled');
                    } else {
                        $('body').removeClass('has-scrolled');
                    }

                });

            }
        },
        // Contact page
        'page_template_contact': {
            init: function() {

                $('#map').mboMaps();

            }
        },
        // Singe project page
        'single_project': {
            init: function() {

                $('.flexslider').flexslider({
                    pauseOnHover   : false,
                    animation      : 'fade',
                    animationLoop  : true,
                    slideshow      : true,
                    slideshowSpeed : 4000,
                    animationSpeed : 1200,
                    controlNav     : true,
                    directionNav   : true
                });

            }
        }
    };

    // The routing fires all common scripts, followed by the page specific scripts.
    // Add additional events for more control over timing e.g. a finalise event
    var UTIL = {
        fire: function(func, funcname, args) {
            var fire;
            var namespace = MBO;
            funcname = (funcname === undefined) ? 'init' : funcname;
            fire = func !== '';
            fire = fire && namespace[func];
            fire = fire && typeof namespace[func][funcname] === 'function';

            if (fire) {
                namespace[func][funcname](args);
            }
        },
        loadEvents: function() {
            // Fire common init JS
            UTIL.fire('common');

            // Fire page-specific init JS, and then finalise JS
            $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
                UTIL.fire(classnm);
                UTIL.fire(classnm, 'finalise');
            });

            // Fire common finalise JS
            UTIL.fire('common', 'finalise');
        }
    };

    // Load Events
    $(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.
