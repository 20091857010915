/*
Ajax filtering scripts
*/
jQuery(document).ready(function($) {

    /*-----------------------------------------------------------------------------------
     Blog filtering, isotope, infinite scrolling
     http://www.creativebloq.com/web-design/filter-web-content-infinite-scroll-10134808
     -----------------------------------------------------------------------------------*/
    var ajaxFiltering;

    ajaxFiltering = {

        init: function () {

            // Setup variables
            ajaxFiltering.masonryWrapper = $('.masonry-wrapper');
            ajaxFiltering.pagination = '.load-more a';

            // Once images are loaded run Isotope
            ajaxFiltering.isotope();

            // Delegate the click event to work with ajaxed content see (http://api.jquery.com/live/)
            $(document).on('click', ajaxFiltering.pagination, ajaxFiltering.loadMore);

        },

        isotope:function() {

            // Wait for images to be loaded
            ajaxFiltering.masonryWrapper.imagesLoaded(function() {

                // init isotope, then insert all items from hidden .alpha
                ajaxFiltering.masonryWrapper.isotope({
                    columnWidth: '.masonry-element',
                    itemSelector: '.masonry-element',
                    resizable: false,
                    transitionDuration: 0,
                    layoutMode: 'fitRows'
                });

                $('.load-more').css('display', 'block');

                ajaxFiltering.masonryWrapper.trigger('complete');

            });

        },

        loadMore:function(e) {

            e.preventDefault();

            $('.load-more .btn').text('Loading...');

            // Get url of clicked link
            var $url = $('.load-more a').attr('href');

            $.get($url, function($data) {

                $data = $($data).find('#content');

                $data.imagesLoaded(function() {

                    // Replace the pagination
                    var $newPagination = $data.find('.load-more');

                    if (!$newPagination || $newPagination.length === 0) {
                        $newPagination =  '';
                    }

                    $('.load-more').remove();
                    $('.page-footer').append($newPagination);
                    $('.load-more').css('display', 'block');

                    // Add the new elements to isotope
                    var $new =  $data.find('.masonry-element');
                    ajaxFiltering.masonryWrapper.isotope('insert', $new);

                });

            });
        }

    };

    ajaxFiltering.init();

    // update columnWidth on window resize
    $(window).smartResize(function() {
        ajaxFiltering.isotope();
    });

});
