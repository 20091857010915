(function($) {
    'use strict';

    $.fn.mboMaps = function() {

        var $map = this;

        var mapLat = $map.data('latitude');
        var mapLon = $map.data('longitude');
        var myLatLng = new google.maps.LatLng(mapLat, mapLon);
        var titleLocation = window.location.hash.substring(1);

        // Create a new StyledMapType object, passing it the array of styles,
        // as well as the name to be displayed on the map type control.
        var styles = [{'featureType':'water','elementType':'geometry','stylers':[{'color':'#e9e9e9'},{'lightness':17}]},{'featureType':'landscape','elementType':'geometry','stylers':[{'color':'#f5f5f5'},{'lightness':20}]},{'featureType':'road.highway','elementType':'geometry.fill','stylers':[{'color':'#ffffff'},{'lightness':17}]},{'featureType':'road.highway','elementType':'geometry.stroke','stylers':[{'color':'#ffffff'},{'lightness':29},{'weight':0.2}]},{'featureType':'road.arterial','elementType':'geometry','stylers':[{'color':'#ffffff'},{'lightness':18}]},{'featureType':'road.local','elementType':'geometry','stylers':[{'color':'#ffffff'},{'lightness':16}]},{'featureType':'poi','elementType':'geometry','stylers':[{'color':'#f5f5f5'},{'lightness':21}]},{'featureType':'poi.park','elementType':'geometry','stylers':[{'color':'#dedede'},{'lightness':21}]},{'elementType':'labels.text.stroke','stylers':[{'visibility':'on'},{'color':'#ffffff'},{'lightness':16}]},{'elementType':'labels.text.fill','stylers':[{'saturation':36},{'color':'#333333'},{'lightness':40}]},{'elementType':'labels.icon','stylers':[{'visibility':'off'}]},{'featureType':'transit','elementType':'geometry','stylers':[{'color':'#f2f2f2'},{'lightness':19}]},{'featureType':'administrative','elementType':'geometry.fill','stylers':[{'color':'#fefefe'},{'lightness':20}]},{'featureType':'administrative','elementType':'geometry.stroke','stylers':[{'color':'#fefefe'},{'lightness':17},{'weight':1.2}]}];


        // Create a new StyledMapType object, passing it an array of styles,
        // and the name to be displayed on the map type control.
        var styledMapType = new google.maps.StyledMapType(styles, { name: 'Styled Map' });


        // Create a map object, and include the MapTypeId to add
        // to the map type control.
        var mapOptions = {
            zoom: 17,
            disableDefaultUI: true,
            center: myLatLng,
            scrollwheel: false,
            draggable: !('ontouchend' in document),
            mapTypeControlOptions: {
               mapTypeIds: [google.maps.MapTypeId.ROADMAP, 'styled_map']
            }
        };

        var map = new google.maps.Map(document.getElementById('map'), mapOptions);

        // Associate the styled map with the MapTypeId and set it to display.
        map.mapTypes.set('styled_map', styledMapType);
        map.setMapTypeId('styled_map');

        var icon = {
            url: mbo_vars.template_directory + '/assets/img/brand-mark.png',
            size: new google.maps.Size(42, 42),
            scaledSize: new google.maps.Size(42, 42)
        };

        var markersArray = [];

        function addMarker(location) {
            var marker = new google.maps.Marker({
                position: location,
                map: map,
                animation: google.maps.Animation.DROP,
                icon: icon,
                optimized: false,
                zIndex: 1
            });
            markersArray.push(marker);
        }

        function clearOverlays() {
            if (markersArray) {
                for (var i = 0; i < markersArray.length; i++) {
                    markersArray[i].setMap(null);
                }
            }
        }

        var updateMarker = function() {

            var $el = $(this);

            if (!$el.closest('li').hasClass('active')) {

                var locationID = $el.data('location');
                var newMapLat = $el.data('latitude');
                var newMapLon = $el.data('longitude');

                var myLatLng = new google.maps.LatLng(newMapLat, newMapLon);

                clearOverlays();
                map.setCenter(myLatLng);
                addMarker(myLatLng);

                $el.closest('ul').find('.active').removeClass('active');
                $el.closest('li').addClass('active');

                $('#locations-wrapper').find('.active').removeClass('active');
                $('#locations-wrapper').find('[data-location="' + locationID + '"]').addClass('active');

            }

            return false;

        };

        addMarker(myLatLng);

        $('.update-marker').on('click', updateMarker);

        // Resize evennt
        var center;
        function calculateCenter() {
            center = map.getCenter();
        }
        google.maps.event.addDomListener(map, 'idle', function() {
            calculateCenter();
        });
        google.maps.event.addDomListener(window, 'resize', function() {
            map.setCenter(center);
        });


        // If hash set then click on that icon
        if (titleLocation) {
            $('#button-' + titleLocation).trigger( 'click' );
        }

        return $map;

    };

})(jQuery);
